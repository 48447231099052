<template>
  <div class="flex-row align-items-center">
    <CRow class="justify-content-center headimg mb-5">
      <CCol md="12" class="p-0">
        <div
          style="
            background-image: url('img/Dashboard.png');
            background-position: 50% 50%;
            background-size: cover;
            height: 200px;
          "
        >
          <h2 class="text-center text-white middle-center">
            <b>Ajukan <span class="text-success">Permohonan Baru</span> </b>
          </h2>
          <!-- <CRow class="justify-content-center bottom-div">
                        <router-link :to="{path: 'permohonan-tanaman-pangan'}" class="col-2 bg-success permohonan-head text-white py-3">
                            <b>Tanaman Pangan</b>
                        </router-link >
                        <div class="col-2 bg-success permohonan-head text-white py-3">
                            <b>Tanaman Perkebunan</b>
                        </div >
                        <router-link :to="{path: 'permohonan-hortikultura'}" class="col-2 bg-success permohonan-head text-white py-3">
                            <b>Hortikultura</b>
                        </router-link >
                        <div class="col-2 bg-success permohonan-head text-white py-3">
                            <b>Sumber Daya Genetik</b>
                        </div >
                    </CRow> -->
        </div>
      </CCol>
    </CRow>
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCard>
            <CCardHeader
              class="top-radius-15 bg-success text-white text-center"
            >
              <h4 class="my-0"><b>Module - Step 1</b></h4>
            </CCardHeader>
            <CCardBody>
              <CRow class="justify-content-center">
                <CCol
                  md="2"
                  class="text-center"
                  :class="{
                    disabled:
                      !list_perusahaan_module.includes('Tanaman Pangan'),
                  }"
                >
                  <center>
                    <div
                      class="img-responsive hover-pointer"
                      style="
                        background-image: url(img/tp.png);
                        background-size: cover;
                        background-position: 50% 50%;
                        width: 100px;
                        height: 100px;
                      "
                      v-on:click="getKategoriModule('Tanaman Pangan')"
                    ></div>
                    <h5 class="mt-3">Tanaman Pangan</h5>
                  </center>
                </CCol>
                <CCol
                  md="2"
                  class="text-center"
                  :class="{
                    disabled: !list_perusahaan_module.includes('Perkebunan'),
                  }"
                >
                  <center>
                    <div
                      class="img-responsive hover-pointer"
                      style="
                        background-image: url(img/bun.png);
                        background-size: cover;
                        background-position: 50% 50%;
                        width: 100px;
                        height: 100px;
                      "
                      v-on:click="getKategoriModule('Perkebunan')"
                    ></div>
                    <h5 class="mt-3">Perkebunan</h5>
                  </center>
                </CCol>
                <CCol
                  md="2"
                  class="text-center"
                  :class="{
                    disabled: !list_perusahaan_module.includes('Hortikultura'),
                  }"
                >
                  <center>
                    <div
                      class="img-responsive hover-pointer"
                      style="
                        background-image: url(img/horti.png);
                        background-size: cover;
                        background-position: 50% 50%;
                        width: 100px;
                        height: 100px;
                      "
                      v-on:click="getKategoriModule('Hortikultura')"
                    ></div>
                    <h5 class="mt-3">Hortikultura</h5>
                  </center>
                </CCol>
                <CCol
                  md="2"
                  class="text-center"
                  :class="{
                    disabled: !list_perusahaan_module.includes(
                      'Sumber Daya Genetik'
                    ),
                  }"
                >
                  <center>
                    <div
                      class="img-responsive hover-pointer"
                      style="
                        background-image: url(img/sdg.png);
                        background-size: cover;
                        background-position: 50% 50%;
                        width: 100px;
                        height: 100px;
                      "
                      v-on:click="getKategoriModule('Sumber Daya Genetik')"
                    ></div>
                    <h5 class="mt-3">Sumber Daya Genetik</h5>
                  </center>
                </CCol>
                <CCol
                  md="2"
                  class="text-center"
                  :class="{
                    disabled: !list_perusahaan_module.includes(
                      'Benih Tanaman Pakan Ternak'
                    ),
                  }"
                >
                  <center>
                    <div
                      class="img-responsive hover-pointer"
                      style="
                        background-image: url(img/hpt.png);
                        background-size: cover;
                        background-position: 50% 50%;
                        width: 100px;
                        height: 100px;
                      "
                      v-on:click="
                        getKategoriModule('Benih Tanaman Pakan Ternak')
                      "
                    ></div>
                    <h5 class="mt-3">Benih Tanaman Pakan Ternak</h5>
                  </center>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow
        class="justify-content-center hide-all hide-module"
        id="kategoriModule"
      >
        <CCol md="12">
          <CCard>
            <CCardHeader
              class="top-radius-15 bg-success text-white text-center"
            >
              <h4 class="my-0">
                <b>Pilih Kategori Benih {{ label_selected_module }} - Step 2</b>
              </h4>
            </CCardHeader>
            <CCardBody>
              <CRow class="justify-content-center">
                <div v-if="loading_kategori" class="col-md-12 text-center">
                  <center>
                    <i class="fa fa-refresh fa-spin"></i> Loading...
                  </center>
                </div>
                <div
                  class="text-center col-md-2"
                  v-for="(
                    value_data_kategori, key_data_kategori
                  ) in data_kategori"
                  v-bind:key="key_data_kategori"
                  v-else
                >
                  <center>
                    <div
                      class="img-responsive hover-pointer"
                      style="
                        background-image: url(img/surat-icon.png);
                        background-size: cover;
                        background-position: 50% 50%;
                        width: 100px;
                        height: 100px;
                      "
                      v-on:click="getDataTujuan(key_data_kategori)"
                    ></div>
                    <h5 class="mt-3">{{ value_data_kategori.m_nama }}</h5>
                  </center>
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="justify-content-center hide-all hide-jenis" id="tujianBenih">
        <CCol md="12">
          <CCard>
            <CCardHeader
              class="top-radius-15 bg-success text-white text-center"
            >
              <h4 class="my-0">
                <b>Pilih Tujuan Penggunaan Benih - Step 3</b>
              </h4>
            </CCardHeader>
            <CCardBody>
              <CRow class="justify-content-center" id="row-tujianBenih-loading">
                <!-- <div class="text-center col-md-2" v-for="(value_data_tujuan_benih, key_data_tujuan_benih) in data_tujuan_benih" v-bind:key="key_data_tujuan_benih">
                                    <center>
                                        <div class="img-responsive" style="background-image: url(img/surat-icon.png); background-size: cover; background-position: 50% 50%;width: 100px; height: 100px;" v-on:click="redirectPengajuan(value_data_tujuan_benih.id, value_data_tujuan_benih.tpb_nama)"></div>
                                        <h5 class="mt-3">{{value_data_tujuan_benih.tpb_nama}}</h5>
                                    </center>
                                </div> -->
              </CRow>
              <CRow
                class="justify-content-center hide-all"
                id="row-tujianBenih"
              >
                <!-- <div class="col-md-12" >
                                    <ol>
                                        <li v-for="(value_data_tujuan_benih, key_data_tujuan_benih) in data_tujuan_benih" v-bind:key="key_data_tujuan_benih"  v-on:click="redirectPengajuan(value_data_tujuan_benih.id, value_data_tujuan_benih.tpb_nama)" class="hover-pointer text-capitalize">{{value_data_tujuan_benih.tpb_nama}}</li>
                                    </ol>
                                </div> -->
                <div class="col-md-12">
                  <v-select
                    :options="data_tujuan_benih"
                    v-model="tujuan_select"
                    label="tpb_nama"
                    placeholder="Tujuan Penggunaan Benih"
                    @input="redirectPengajuan"
                  ></v-select>
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
const null_array = [];
export default {
  name: "AjukanPermohonan",
  data() {
    return {
      label_selected_module: "",
      data_kategori: null_array,
      data_tujuan_benih: null_array,
      data_selected_kategori: "",
      data_selected_kategori_id: "",
      data_selected_module_categories: "",
      selectTujuan: "",
      session_data: JSON.parse(this.session),
      tujuan_select: "",
      loading_kategori: false,
      is_pemasukan_selected: "",
      list_perusahaan_module: [],
    };
  },
  mounted() {
    $(".select2").select2({
      placeholder: "-- Pilih --",
    });
    $(".hide-all").hide();
  },
  methods: {
    getKategoriModule(module_selected) {
      if (!this.list_perusahaan_module.includes(module_selected)) {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Anda tidak memiliki akses untuk permohonan " + module_selected,
        });
        return false;
      }
      // if (module_selected != 'Hortikultura') {
      //     return false;
      // }
      this.label_selected_module = module_selected;
      $("#kategoriModule").show();
      this.loading_kategori = true;
      // ?kategori=Perkebunan&modules_id=4&module_categories_id=9
      axios
        .get(this.apiLink + "api/master/module", {
          params: {
            kategori: module_selected,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          this.loading_kategori = false;
          this.data_kategori = response.data.data.master;
        });
    },
    getDataTujuan(kategori_key) {
      let self = this;
      $("#tujianBenih").hide();
      var alljenis = this.data_kategori[kategori_key];
      this.data_selected_kategori_id = alljenis.id;
      var selected_kategori = (this.data_selected_kategori = alljenis.m_nama);
      this.is_pemasukan_selected = alljenis.is_pemasukan;
      console.log(alljenis.is_pemasukan);
      // IF untuk handling pengembangan fitur horti
      if (this.label_selected_module == "Hortikultura") {
        if (
          !alljenis.is_pemasukan &&
          this.label_selected_module == "Hortikultura"
        ) {
          this.$router.push({
            name: "FormPengajuanPengeluaranHortikultura",
            query: {
              label_selected_module: self.label_selected_module,
              data_selected_kategori: self.data_selected_kategori,
              data_selected_kategori_id: self.data_selected_kategori_id,
              tujuan_value: "",
              tujuan_id: "",
            },
          });
        } else {
          $("#tujianBenih").show();
          $("#row-tujianBenih").hide();
          $("#row-tujianBenih-loading").html(
            '<div class="col-md-12" class="text-center"><center><i class="fa fa-refresh fa-spin"></i> Loading...</center> </div>'
          );
          // ?kategori=Perkebunan&modules_id=4&module_categories_id=9
          axios
            .get(this.apiLink + "api/master/tujuan_penggunaan_benih", {
              params: {
                tipe_pemohon_id: this.session_data.tipe_pemohon_id,
                kategori_module: this.label_selected_module,
                is_pemasukan: this.is_pemasukan_selected,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              $("#row-tujianBenih-loading").empty();
              $("#row-tujianBenih").show();
              this.data_tujuan_benih = response.data.data.master;
              console.log(this.data_tujuan_benih);
            });
        }
      } else {
        if (
          !alljenis.is_pemasukan &&
          this.label_selected_module == "Hortikultura"
        ) {
          this.$router.push({
            path: "form-pengajuan-pengeluaran/",
            query: {
              label_selected_module: self.label_selected_module,
              data_selected_kategori: self.data_selected_kategori,
              data_selected_kategori_id: self.data_selected_kategori_id,
              tujuan_value: "",
              tujuan_id: "",
            },
          });
        }
        // else if(alljenis.is_pemasukan && this.label_selected_module == "Sumber Daya Genetik"){
        //     this.$router.push({
        //         path: "form-pengajuan-pemasukan/",
        //         query:{
        //             label_selected_module : self.label_selected_module,
        //             data_selected_kategori : self.data_selected_kategori,
        //             data_selected_kategori_id : self.data_selected_kategori_id,
        //             tujuan_value : '',
        //             tujuan_id : '',
        //             tanaman_kategori_id: '',
        //         }
        //     });
        // }
        else {
          $("#tujianBenih").show();
          $("#row-tujianBenih").hide();
          $("#row-tujianBenih-loading").html(
            '<div class="col-md-12" class="text-center"><center><i class="fa fa-refresh fa-spin"></i> Loading...</center> </div>'
          );
          // ?kategori=Perkebunan&modules_id=4&module_categories_id=9
          axios
            .get(this.apiLink + "api/master/tujuan_penggunaan_benih", {
              params: {
                tipe_pemohon_id: this.session_data.tipe_pemohon_id,
                kategori_module: this.label_selected_module,
                is_pemasukan: this.is_pemasukan_selected,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              $("#row-tujianBenih-loading").empty();
              $("#row-tujianBenih").show();
              this.data_tujuan_benih = response.data.data.master;
              console.log(this.data_tujuan_benih);
            });
        }
      }
    },
    redirectPengajuan() {
      let self = this;
      let tujuan_id = this.tujuan_select.id;
      let tujuan_value = this.tujuan_select.tpb_nama;
      // jenis_key
      // this.data_selected_kategori = alljenis.m_nama;
      // IF untuk Handling Pengembangan hortikultura

      if (this.is_pemasukan_selected) {
        this.$router.push({
          path:
            "form-pengajuan-pemasukan/" +
            self.label_selected_module.replaceAll(" ", "-") +
            "/",
          query: {
            label_selected_module: self.label_selected_module,
            data_selected_kategori: self.data_selected_kategori,
            data_selected_kategori_id: self.data_selected_kategori_id,
            tujuan_value: tujuan_value,
            tujuan_id: tujuan_id,
            tanaman_kategori_id: self.tujuan_select.tanaman_kategori_id,
          },
        });
      } else {
        // alert('b');
        this.$router.push({
          path:
            "form-pengajuan-pengeluaran/" +
            self.label_selected_module.replaceAll(" ", "-") +
            "/",
          query: {
            label_selected_module: self.label_selected_module,
            data_selected_kategori: self.data_selected_kategori,
            data_selected_kategori_id: self.data_selected_kategori_id,
            tujuan_value: tujuan_value,
            tujuan_id: tujuan_id,
            tanaman_kategori_id: self.tujuan_select.tanaman_kategori_id,
          },
        });
      }
    },
  },
  created() {
    axios
      .get(this.apiLink + "api/crud/perusahaan/perusahaan_module_kbli", {
        params: {
          nib: this.session_data.nib,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_perusahaan_module = response.data;
        var res_perusahaan_module_data = res_perusahaan_module.data[0];
        console.log(res_perusahaan_module_data);
        if (res_perusahaan_module.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_perusahaan_module.data.message,
          });
        } else {
          // this.list_perusahaan_module = res_perusahaan_module_data;
          this.list_perusahaan_module = [
            "Hortikultura",
            "Perkebunan",
            "Tanaman Pangan",
            "Sumber Daya Genetik",
            "Benih Tanaman Pakan Ternak",
          ];
        }
      });
  },
};
</script>